//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      url: require("../../assets/img/website.jpg"),
    };
  },
};
